export default () => ({
    scrollToId (id) {
        const elements = [...document.querySelectorAll('.more_about_ada')].filter(ele => {
            return ele.offsetParent !== null;
        })

        if (elements.length === 0) {
            return;
        }

        elements[0].scrollIntoView({
            behavior: 'smooth'
        });
    },
})
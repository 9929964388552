import setOverflow from "../helpers/setOverflow";

export default () => ({
    open: false,

    handle (e) {
        switch (e.detail) {
            case 'open_menu':
                this.show();
                break;
                
            case 'reset':
                this.hide();
                break;
        }
    },

    hide () {
        setOverflow(true);
        this.open = false;
    },

    show () {
        setOverflow(false);
        this.open = true;
    }
})
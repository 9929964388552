import setOverflow from "../helpers/setOverflow";

export default () => ({
    selected: 'menu',
    open: false,

    autohide (e) {
        if (e?.target?.id !== 'modal-container') {
            return;
        }

        this.hide();
    },

    handle (e) {
        switch (e.detail) {
            case 'open_menu_modal':
                this.show('menu');
                break;

            case 'open_booking_modal':
                this.show('booking');
                break;

            case 'open_callback_modal':
                this.show('callback');
                break;

            case 'reset':
                this.hide();
                break;
        }
    },

    hide () {
        setOverflow(true);
        this.open = false;
        this.selected = 'menu';
    },

    show (modal = 'menu') {
        this.$dispatch('action', 'reset');
        setOverflow(false);
        this.selected = modal;
        this.open = true;
    },
})
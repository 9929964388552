export default () => ({
    next () {
        this.$refs.items.scroll({ left: this.$refs.items.scrollLeft + this.width, top: 0, behavior: 'smooth' })
    },

    previous () {
        this.$refs.items.scroll({ left: this.$refs.items.scrollLeft - this.width, top: 0, behavior: 'smooth' })
    },

    width: 0,
})
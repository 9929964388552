import Player from '@vimeo/player'

let video;

export default () => ({
    playing: false,

    async setup (id) {
        const iframe = document.getElementById(id)

        if (!iframe) {
            return;
        }

        video = new Player(iframe);
    },

    async play () {
        if (!video) {
            return;
        }

        await video.play();
        this.playing = true;
    }
})
export default () => ({
    init () {
        if (!this.$refs.listing) {
            return;
        }

        const url = new URL(window.location);
        
        if (url.searchParams.has('category') || url.searchParams.has('month')) {
            this.$refs.listing.scrollIntoView({ behavior: 'smooth' });
        }
    },

    filterBy (key, value) {
        const url = new URL(window.location);

        value
            ? url.searchParams.set(key, value)
            : url.searchParams.delete(key);
        
        window.location = url;
    },

    reset () {
        const url = new URL(window.location);

        ['category', 'month'].forEach(key => url.searchParams.delete(key));

        window.location = url;
    }
})
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';

export default () => ({
    init() {
        const swiper = new Swiper('.swiper-container', {
            modules: [Navigation],
            slidesPerView: 2.1,
            spaceBetween: 20,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                768: {
                    slidesPerView: 3.2,
                    spaceBetween: 20,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                },
                1024: {
                    slidesPerView: 4.3,
                    spaceBetween: 20,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                },
            },

        });
    },


})

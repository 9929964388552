import axios from 'axios';

export default () => ({
    height: '0px',
    submitted: false,
    failed: false,
    errors: {},
    
    submit (e) {
        e.preventDefault();

        if (this.$refs.form) {
            this.height = `${this.$refs.form.scrollHeight}px`;
        }
        
        const data = new FormData(e.target);

        axios({
            method: e.target.method,
            url: e.target.action,
            data: new FormData(e.target),
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            }
        }).then(() => {
            this.submitted = true;
        }).catch(err => {
            if (err.response && err.response.status === 400) {
                this.errors = err.response.data.error;

                return;
            }

            this.submitted = true;
            this.failed = true;
        })
    }
})